/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 40px;
  height: 32px;
  margin: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: gray;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 36px;
  width: 36px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}
.bm-cross-button {
  transform: scale(2);
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: rgb(51, 108, 166);
  padding: 2.5em 0 0 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0;
}

/* Individual item */
.bm-item,
.bm-item .accordion-button{
  background: transparent;
  font-size: x-large;
  color: white;
}

.bm-item .accordion-button:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.bm-item .leaf .accordion-button::after {
  background-image: none;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.homeIcon {
	margin-right: 16px;
  margin-left: 70px;
  margin-top: 8px;
  margin-bottom: 10px;
  display: inline-block;
}
.homeIconPadding {
  margin-left: 70px;
  display: inline-block;
}

.version {
  font-size: smaller;
}

/* Disable hover effect (blue text) on links */
a:hover {
  color: inherit;
}
