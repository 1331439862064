.grid-stack-item-content.clock {
	background: hsl(0, 0%, 33%);
}
.grid-stack-item-content.clock .datetime {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-top: 2vh;
}
.grid-stack-item-content.clock .time {
	font-size: 8.6vw;
	line-height: 1;
	font-weight: bold;
	color: white;
}
.grid-stack-item-content.clock .date {
	line-height: 1;
	font-size: 4.3vw;
	font-weight: bold;
	color: white;
}

#timer-dialog .time {
	font-size: 600%;
	font-weight: bold;
}

.tea-timer-modal {
	width: 90%;
	max-width: 100%;
}
