
.bufferTank .heatingElement .room-temperature_target {
	position: relative;
	font-size: small;
	min-width: 2em;
	top: 0.3em;
}

.bufferTank .heatingElement {
	font-size: 50%
}
.bufferTank .heatingElement .room-temperature:not(:empty)::after {
	top: 3pt;
}
.bufferTank .heatingElement .room-temperature_target:not(:empty)::after {
	display: none;
}

.bufferTank .room-power-icon {
	display: none;
}

.bufferTank .room-content .room-effort {
	display: none;
}
