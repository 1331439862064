.grid-stack {
	width: 100%;
}
.grid-stack-item-content {
	color: black;
	text-align: center;
	border: 10px;
	border-radius: 20px;
	padding-left: 0.5em;
	padding-right: 0.5em;
}
