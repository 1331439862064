.room-effort {
  left: 0;
  height: 3px;
  bottom: 1px;
  position: absolute;
  opacity: 50%;
}

.grid-stack-item-content .room-name {
	font-weight: bolder;
}

.grid-stack-item-content .room-temperature {
	padding-top: 0.08em;
	display: table-cell;
	font-size: 250%;
	line-height: 1.3;
	font-weight: bold;
	padding-right: 0.2em;
	color: white;
}
.grid-stack-item-content .room-temperature:not(:empty)::after,
.room-temperature_target:not(:empty)::after {
	content: "°C";
	font-size: x-small;
	font-weight: lighter;
	vertical-align: top;
	position: relative;
	top: 6pt;
}
.room-temperature_target:not(:empty)::after {
	top: 1pt;
}

.grid-stack-item-content .room-humidity {
	font-size: large;
	font-weight: normal;
	display: table-cell;
}
.grid-stack-item-content .room-humidity:not(:empty)::after {
	content: "%";
	font-size: 45%;
	font-weight: lighter;
	vertical-align: top;
	position: relative;
	top: 3pt;
}

.grid-stack-item-content .room-power {
	display: table-cell;
	font-size: xx-large;
	font-weight: bold;
	padding-right: 0.2em;
	color: white;
}

.grid-stack-item-content .room-power:not(:empty)::after {
	content: "kW";
	font-size: x-small;
	font-weight: lighter;
	vertical-align: 0%;
}

/* Hide stuff for minimal display */
.grid-stack-item-content.minimal .room-humidity,
.grid-stack-item-content.minimal .room-power-icon,
.grid-stack-item-content.minimal .room-temperature_trend
{
	visibility: hidden;
	width: 0px;
	max-width: 0px;
	height: 0px;
	max-height: 0px;
	overflow: hidden;
	padding: 0px 0px 0px 0px;
	border: 0px 0px 0px 0px;
}

.grid-stack-item-content .temporary-change-active
{
	color: yellow;
}

.grid-stack-item-content .room-temperature_target {
	position: absolute;
	top: -0.35em;
	right: 0;
	font-size: large;
	font-weight: normal;
}

.grid-stack-item-content .room-power-icon {
	position: absolute;
	top: 1.0em;
	right: 0.5em;
}

.room-temperature_trend {
	display: table-cell;
	padding-right: 0.5em;
}
.room-temperature_trend svg {
	vertical-align: baseline;
}

.windowIcon {
  position:absolute;
  right: 1.0em;
}

.flexFillHeight {
	flex: 1 1 auto;
}

.grid-stack-item-content .zoomed {
	transform-origin: 0 0;
}
