#statusLine {
	display: inline-block;
	padding-left: 10px;
	padding-right: 10px;
	margin-top: 5px;
	margin-bottom: 10px;

}
#currentProfile {
	cursor: pointer;
}

.infoTextDiv {
	font-size: large;
	font-weight: bolder;
	text-align: left;
	background: rgb(146, 178, 164);
	margin-top: 5px;
	margin-bottom: 10px;
	margin-right: 20px;
	border-radius: 20px;
	padding-left: 10px;
	padding-right: 10px;
	padding-top: 9px;
	padding-bottom: 9px;
	display: inline-block;
}

a .infoTextDiv {
	color: black;
}

.eventLog {
	color: greenyellow;
	text-align: left;
	font-family: monospace;
	margin: 10px;
}


a {
	text-decoration: none;
  	color: #f7f7f7;
  	outline: none;
}


.center {
	text-align: center;
}
.time-done.button {
	font-size: 400%;
	font-weight: bold;
}
.pupup {
	float:left;
}


.linkSensors {
	color: greenyellow;
	text-align: left;
}

.linkSensors .sensor{
}

.filter {
	color: greenyellow;
	text-align: left;
	padding-bottom: 5px;
	padding-top: 5px;
}

.filter input[type="checkbox" i] {
	margin-left: 1.5em;
}

.pump {
	animation: spin 10s infinite linear;
	float:right;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}