html {
  touch-action: manipulation;
}

html, body {
	height: 100%;
	font-size: 100%;
	font-family: sans-serif;
	padding: 0;
	margin: 0;
	border: 0;
	background-color: rgb(33, 36, 52);
}

.menu {
  min-height: 56px;
  display: inline-block;
}
.app {
  display: block;
}

html, body, div, img, span, h1, h2, h3, h4, button {
  /* Disable selection of text (annoying when clicking fast) */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.cursorPointer {
	cursor: pointer;
}
