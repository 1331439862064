.ventilation {
	padding-left: 0;
	padding-right: 0;
}

.grid-stack-item-content .room-ventilation-speed {
	font-size: 250%;
	font-weight: bold;
	color: white;
	line-height: normal;
}
.grid-stack-item-content .room-ventilation-speed:not(:empty)::after {
	content: "%";
	font-size: small;
	font-weight: lighter;
	vertical-align: center;
}

.room-ventilation-mode-icon {
	rotate: 90deg;
}
